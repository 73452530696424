<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">管理体系文件分类</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">科学划分文件分类</p>
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        文件管理混乱，重复内容的不同文件互相干扰
        <br />
        文件层级太多
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        提供文件分类培训<br />
        辅导建立文件分类
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>